@font-face {
  font-family: ReenieBeanie;
  src: url(./assets/ReenieBeanie.ttf);
}
@font-face {
  font-family: Whipsnap;
  src: url(./assets/whipsnap.ttf);
}

$primary-color-1: #802ccc;
$primary-color-2: #73d3a9;
$secondary-color: #fae231;

body {
  margin: 0;
  font-family: "Helvetica Neue";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  font-family: "Helvetica Neue";
  box-sizing: border-box;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
  border-radius: 30px;
}

.menu {
  display: flex;
  flex-direction: row;
  padding: 1em 0.5em;
  .menu-items {
    display: flex;
    flex-direction: column;
    align-items: start;
    .menu-item {
      text-decoration: none;
      font-size: 1.2rem;
      font-weight: 600;
      color: black;
      cursor: pointer;
      margin: 0.4em;
    }
    .menu-item.active {
      color: $primary-color-2;
    }
  }
  .menu-content {
    padding-left: 2em;
    padding-right: 0.5em;
    margin-left: 1em;
    max-width: 500px;
    border-left: 6px solid $secondary-color;
    text-align: justify;
    hyphens: auto;
    -webkit-hyphens: auto;
    word-spacing: -0.05em;
    h4:first-child {
      margin-top: 0;
    }
    div {
      font-weight: 500;
      font-size: 1.1rem;
    }
  }
}
.email {
  background-color: #fff;
  padding: 0.5em 1em;
  display: flex;
  align-items: center;
  label {
    font-size: 1.2rem;
    font-weight: 600;
    color: $primary-color-2;
    margin-right: 0.5em;
  }
}

.App {
  width: 100vw;
  text-align: center;

  a {
    color: white;
    text-decoration: none;
    font-weight: bold;
  }

  .header {
    flex: 0 auto;
    display: flex;
    align-items: center;
    padding: 0 0.5em;

    .buttons {
      flex: 1;
      justify-content: space-between;
      display: flex;
      padding: 0 1em;
      margin-right: 3em;
    }
  }

  .footer {
    .links {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 0.5em;
      width: 100%;
    }
  }

  #hero-section {
    background-color: $primary-color-2;
    min-height: 100vh;
    .handlebar_wrapper {
      display: flex;
      width: 20px;
      height: 100%;
      justify-content: center;
      position: absolute;
      top: 0%;
      bottom: 0%;
      right: 0;
      z-index: 3;
      cursor: pointer;
      .handlebar_image {
        max-width: none;
        position: absolute;
        top: 50%;
        left: -15px;
      }
    }
    .hero-wrapper {
      flex-direction: column;
      display: flex;
      z-index: 1;
      background-image: url("./assets/line2.svg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position-y: center;
      width: 100vw;
    }
    .hero-clipping-wrapper {
      background-color: $primary-color-1;
      z-index: 2;
      overflow: hidden;
      position: absolute;
      top: 0%;
      bottom: 0%;
      left: 0%;
      right: 0%;
      min-height: 100vh;
      height: fit-content;
    }
  }

  .slogan {
    padding-bottom: 1em;
    position: relative;
    width: fit-content;
    align-self: center;
    margin-left: -6em;
    text-align: left;
    h1 {
      font-size: 5rem;
      width: 650px;
      line-height: 1.2;
      font-weight: 700;
    }
    .text-svg {
      width: 18rem;
      position: absolute;
      bottom: -10%;
    }
    .text-svg {
      left: 500px;
    }
    .text-svg.family {
      left: 540px;
    }
    // counter act the swiper padding top so swiper will not be too low
    margin-bottom: -10em;
  }

  .swiper {
    width: 100%;
    padding-top: 12em; // let Card has space to grow when enlarged
    padding-bottom: 8em;
  }

  .card {
    transition: transform 0.4s;
    width: fit-content;
    height: 320px;
    margin: auto;
    .content {
      border-radius: 20px;
      p {
        text-align: justify;
        hyphens: auto;
        -webkit-hyphens: auto;
        word-spacing: -0.05em;
      }
    }
    .content.small {
      width: 220px;
      height: 320px;
      color: $secondary-color;
    }
    .content.large {
      display: none;
      width: 240px;
      height: fit-content;
      background-color: #d6d5d5;
      padding: 0.5em 0;
    }
  }

  .swiper-slide-active {
    .content {
      transform: scale(1.05);
      border: 5px solid $secondary-color;
    }
  }

  .swiper-slide-active .card:hover {
    cursor: pointer;
  }

  .swiper-slide-active .card.clicked {
    transform: scale(1.4) translateY(-80px);
    .small {
      display: none;
    }
    .large {
      display: block;
    }
  }
}

@media (max-width: 868px) {
  .App {
    #hero-section {
      .header {
        .buttons {
          justify-content: flex-end;
          margin-right: 0;
        }
      }
      .hero-wrapper {
        background-image: url("./assets/line2.svg");
        background-position-x: right;
        background-position-y: 80px;

        .slogan {
          padding: 1em;
          margin-top: 0em;
          margin-bottom: -6em;
          margin-left: 1.6em;
          h1 {
            font-size: 2rem;
            width: 80%;
          }
          .text-svg {
            width: 8em;
            left: 200px;
            top: 40px;
          }
          .text-svg.family {
            left: 220px;
          }
        }
        .swiper {
          padding-top: 8em; // let Card has space to grow when enlarged
          padding-bottom: 3em;
        }

        .swiper-slide-active .card.clicked {
          transform: scale(1.2) translateY(-70px);
        }

        .footer {
          margin-top: 1em;
          .links {
            flex-direction: column;
          }
        }
      }
    }
  }
  .menu {
    max-height: 400px;
    .menu-item {
      margin: 0.4em 0;
    }
    .menu-content {
      overflow-y: scroll;
      padding-left: 0.5em;
      margin-left: 0.2em;
      div {
        font-size: 1rem;
      }
    }
  }
  .email {
    width: 300px;
  }
}
